import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Query from "./Query";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";

export default function BlogDetails() {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState({});
  const [moreBlogs, setMoreBlogs] = useState([]);
  const navigate = useNavigate();

  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(
        `${Front_API_URL}/FrontBlogDetails/${id}`

      );
      setBlogDetails(response.data);
    } catch (error) {
      console.error("Error fetching blog details", error);
    }
  };
  const truncateString = (str, num) => {
    return str.length > num ? str.slice(0, num) + '...' : str;
};
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};
  const fetchMoreBlogs = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/moreBlogs`);
      setMoreBlogs(response.data.data); // Adjusting to use the data from the pagination response
    } catch (error) {
      console.error("Error fetching more blogs", error);
    }
  };

  useEffect(() => {
    fetchBlogDetails();
    fetchMoreBlogs();
  }, [id]);
  

  return (
    <>
      {/* Start Page Title Area */}
      <div className="banner-area events-details">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{blogDetails.heading}</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                    <i className="flaticon-fast-forward"></i>
                    <Link
                      onClick={() => navigate(-1)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Blogs
                    </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active">{blogDetails.category}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}

      {/* Blogs */}
      <section className="single-event">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="event-slider">
                <div className="single-slider">
                  <div className="event-img">
                    <img
                      src={`${Front_URL}/storage/images/blogs/${blogDetails.image}`}
                      alt="blog" style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                  <div className="content">
                    <h2>{blogDetails.heading}</h2>
                    {/* <p>{stripHtmlTags(blogDetails.description)}</p> */}
                    <div dangerouslySetInnerHTML={{ __html: blogDetails.description }} />

                  </div>
                </div>
              </div>
              <div className="share">
                <ul className="share-list">
                  <li>
                    <p className="share-p">Share</p>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-5">
              <div className="right-content">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control about-search"
                      placeholder="Search..."
                    />
                  </div>
                  <button type="submit" disabled>
                    <i className="flaticon-search"></i>
                  </button>
                </form>

                <p className="visit">More Blogs</p>

                {moreBlogs.map((blog) => (
                  <div className="single-content" key={blog.id}>
                    <div className="content-img">
                      <Link to={`/blog-details/${blog.id}`}>
                        <img src={`${Front_URL}/storage/images/blogs/${blog.image}`} alt="blog" />
                      </Link>
                    </div>
                    <div className="content">
                      <Link to={`/blog-details/${blog.id}`}>
                        <h2>{blog.title}</h2>
                      </Link>
                      {/* <p className="calender">
                        <i className="flaticon-calendar"></i> {blog.comp_date}
                      </p> */}
                      <p>{truncateString(stripHtmlTags(blog.description), 106)}</p>
                      <Link to={`/blog-details/${blog.id}`} className="line-bnt">
                        Read More <i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                ))}

                <Link to="/blogs" className="box-btn">
                  More Blogs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End Blogs */}

      {/* Contact Area */}

      {/* <Query /> */}
      {/* End Contact Area */}
    </>
  );
}
