import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
export default function Music() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const slides = [
    {
      image: "assets/images/music-side.png",
      title: "Music",
      description:
        "The song opens with a gentle strumming of an acoustic guitar, setting a nostalgic and bittersweet tone that permeates the entire track. The tempo is moderate, moving at a comfortable andante pace around 100 beats per minute, which lends a reflective and contemplative mood to the music. Soft synth pads subtly fill the background, creating a lush, atmospheric backdrop that enhances the emotional depth of the piece. The lyrics speak of past memories and lost love, painting a vivid picture of introspection and longing. The vocal delivery is tender and intimate, drawing the listener into the singer's personal journey.",
    },
    {
      image: "assets/images/music-side.png",
      title: "Music",
      description:
        "The song opens with a gentle strumming of an acoustic guitar, setting a nostalgic and bittersweet tone that permeates the entire track. The tempo is moderate, moving at a comfortable andante pace around 100 beats per minute, which lends a reflective and contemplative mood to the music. Soft synth pads subtly fill the background, creating a lush, atmospheric backdrop that enhances the emotional depth of the piece. The lyrics speak of past memories and lost love, painting a vivid picture of introspection and longing. The vocal delivery is tender and intimate, drawing the listener into the singer's personal journey.",
    },
  ];
  return (
    <>
      {/* <!-- Start Page Title Area --> */}
      <div class="banner-area teacher-details">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Music</h2>
                <ul>
                  <li>
                    <a href="index.html"> Home </a>
                    <i class="flaticon-fast-forward"></i>
                    <a href="teachers.html"> Competitions</a>
                    <i class="flaticon-fast-forward"></i>
                    <p class="active">Music</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Teacher --> */}

      <section class="single-teacher-area">
        <div class="container">
          <div class="row">
            <div className="col-lg-8 col-md-7">
              <div className="teacher-slider">
                <div className="single-slider">
                  <div className="teacher-img">
                    <img src={slides[currentIndex].image} alt="music" />
                  </div>
                  <div className="content">
                    <h2>{slides[currentIndex].title}</h2>
                    <p>{slides[currentIndex].description}</p>
                  </div>
                </div>
              </div>
              <button onClick={handlePrev}><FontAwesomeIcon icon={faAngleLeft} /></button>
      <button onClick={handleNext}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>

            <div class="col-lg-4 col-md-5">
              <div class="right-content">
                <form>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control about-search"
                      placeholder="Search..."
                    />
                  </div>
                  <button type="submit">
                    {" "}
                    <i class="flaticon-search"></i>
                  </button>
                </form>

                <p class="visit">Others Competitions</p>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/1.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>John Doe</h2>
                          <p>Music</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/2.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>Evana Doe</h2>
                          <p> Content Writing</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/3.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>John Smith</h2>
                          <p>Stem</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/4.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>Maira Doe</h2>
                          <p>Visual Arts</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/5.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>Evana Doe</h2>
                          <p>Stem</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/6.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>Dina Doe</h2>
                          <p>Languages</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/7.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>Evana Doe</h2>
                          <p>Creative Writing</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-teacher">
                  <div class="row align-items-center">
                    <div class="col-3 col-md-4">
                      <img
                        src="assets/images/competitioner/1.png"
                        alt="Competition"
                      />
                    </div>
                    <div class="col-9 col-md-8">
                      <div class="content">
                        <a href="single-teacher.html">
                          <h2>John Doe</h2>
                          <p>Music</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <a href="teachers.html" class="box-btn">
                  More Competitions
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Teacher Details  --> */}

      {/* <!-- Contact Area --> */}
      <section class="home-contact-area pb-100">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 ps-0">
              <div class="contact-img">
                <img src="assets/images/form.png" alt="contact" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="home-contact-content">
                <h2>What Do You Want to Know?</h2>
                <form id="contactForm">
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          class="form-control"
                          required
                          data-error="Please enter your name"
                          placeholder="Your Name"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          class="form-control"
                          required
                          data-error="Please enter your email"
                          placeholder="Your Email"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          id="phone_number"
                          required
                          data-error="Please enter your number"
                          class="form-control"
                          placeholder="Your Phone"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="msg_subject"
                          id="msg_subject"
                          class="form-control"
                          required
                          data-error="Please enter your subject"
                          placeholder="Your Subject"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <textarea
                          name="message"
                          class="form-control"
                          id="message"
                          cols="30"
                          rows="5"
                          required
                          data-error="Write your message"
                          placeholder="Your Message"
                        ></textarea>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        class="default-btn page-btn box-btn"
                      >
                        Send Message
                      </button>
                      <div id="msgSubmit" class="h3 text-center hidden"></div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Area --> */}
    </>
  );
}
