import React, { useEffect, useState } from "react";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import "../PostedStory.css";

export default function PostedStory({ eventId, pageName }) {
  const [stories, setStories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await axios.get(
          `${Front_API_URL}/stories/${eventId}/${pageName}`
        );
        console.log("Fetched Stories:", response.data); // Log to check for duplicates
        setStories(response.data);
      } catch (error) {
        console.error("Error fetching stories", error);
      }
    };

    fetchStories();
  }, [eventId, pageName]);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };





  // Calculate the current stories to display
  const indexOfLastStory = currentPage * itemsPerPage;
  const indexOfFirstStory = indexOfLastStory - itemsPerPage;
  const currentStories = stories.slice(indexOfFirstStory, indexOfLastStory);

  // Determine the total number of pages
  const totalPages = Math.ceil(stories.length / itemsPerPage);

  return (
    stories.length > 0 && (
      <div className="posted-stories">
        <h2 className="text-center">Posted Stories</h2>
        <div className="container">
          <div className="row">
            {currentStories.map((story, index) => (
              <div className="col-md-12" key={story.id || index}>
                <StoryCard story={story} />
              </div>
            ))}
          </div>
          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleClick(index + 1)}
                  className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  );
}

function StoryCard({ story }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 400; // Limit description length

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return null; // or return an empty string ''
    }
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
};

  return (
    <div className="item story-card">
      <div className="story-content">
        <p className="story-title">{story.heading}</p>
        <div className="posted-bydate">
          <h5><span>Posted By : </span>{story.username}</h5>
          <h5><span>Posted Date : </span> {formatDate(story.created_at)}</h5>
        </div>
        {/* <span className="story-meta">
    {story.name} | {story.date}
  </span> */}
        <p className="story-description">
          {isExpanded ? story.description : `${story.description.slice(0, maxLength)}...`}
        </p>
        {story.description.length > maxLength && (
          <button className="read-more-toggle" onClick={toggleReadMore}>
            {isExpanded ? "Show Less" : "Read More"}
            {!isExpanded && <i className="down-arrow-icon"></i>}
          </button>
        )}
      </div>
    </div>
  );
}
