import React from "react";
import { useState } from "react";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Query() {
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone_number: "",
    msg_subject: "",
    message: "",
  });

  // when type into inputs
  const handleInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  // submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      try {
        axios
          .post(`${Front_API_URL}/submitQuery`, form)
          .then((response) => {
            e.target.reset();
            toast.success(response.data.message);
          })
          .catch((error) => {
                setErrors(error.response.data.errors);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  // form validation
  const validateFormData = () => {
    let errors = {};

    if (!form.name?.trim()) {
      errors.name = "Name is required";
    }
    if (!form.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "Email is invalid";
    }
    if (!form.msg_subject?.trim()) {
      errors.msg_subject = "Subject is required";
    }
    if (!form.message?.trim()) {
      errors.message = "Message is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
    <ToastContainer />

      {/* <!-- Contact Area --> */}
      <section className="home-contact-area pb-100 pt-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 ps-0">
              <div className="contact-img">
                {/* <img src="../assets/images/form.png" alt="contact" /> */}
                <img src="../assets/images/contactusquery.png" alt="contact" />

              </div>
            </div>
            <div className="col-lg-6">
              <div className="home-contact-content">
                <h2>What Do You Want to Know?</h2>
                <form id="contactForm" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={handleInput}
                          value={form.name}
                          className="form-control"
                          data-error="Please enter your name"
                          placeholder="Your Name"
                        />
                        {errors.name && (
                          <span style={{ color: "red" }}>{errors.name}</span>
                        )}
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          onChange={handleInput}
                          value={form.email}
                          className="form-control"
                          data-error="Please enter your email"
                          placeholder="Your Email"
                        />
                        {errors.email && (
                          <span style={{ color: "red" }}>{errors.email}</span>
                        )}
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          id="phone_number"
                          onChange={handleInput}
                          value={form.phone_number}
                          data-error="Please enter your number"
                          className="form-control"
                          placeholder="Your Phone"
                        />
                         {errors.phone_number && (
                          <span style={{ color: "red" }}>{errors.phone_number}</span>
                        )}
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="msg_subject"
                          id="msg_subject"
                          onChange={handleInput}
                          value={form.msg_subject}
                          className="form-control"
                          data-error="Please enter your subject"
                          placeholder="Your Subject"
                        />
                        {errors.msg_subject && (
                          <span style={{ color: "red" }}>{errors.msg_subject}</span>
                        )}
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          onChange={handleInput}
                          value={form.message}
                          cols="30"
                          rows="5"
                          data-error="Write your message"
                          placeholder="Your Message"
                        ></textarea>
                        {errors.message && (
                          <span style={{ color: "red" }}>{errors.message}</span>
                        )}
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button className="default-btn page-btn box-btn">
                        Send Message
                      </button>
                      <div
                        id="msgSubmit"
                        className="h3 text-center hidden"
                      ></div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Area --> */}
    </>
  );
}
