import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Index from './components/Index';
import Main from './components/layout/Main';
import AboutUs from './components/AboutUs';
import Music from './components/Music';
import Events from './components/Events';
import EventsDetails from './components/EventsDetails';
import Faq from './components/Faq';
import ContactUs from './components/ContactUs';
import Signin from './components/SignIn';
import SignUp from './components/SignUp';
import Preloader from './components/Preloader';
import ForgotPassword from "./components/ForgotPassword";
import Blogs from "./components/Blogs";
import BlogDetails from "./components/BlogDetails";
import ResetPassword from './components/ResetPassword';
import EventsDetailsNew from './components/EventsDetailsNew';
import PostStory from './components/PostStory';

import Profile from './components/Profile';
import SubmitCompetition from './components/SubmitCompetition';
import CompanyCompetitionsDetails from './components/CompanyCompetitionsDetails';
import CompanyCompetitions from './components/CompanyCompetitions';
import PageNotFound from './components/PageNotFound';  // Correct import for the 404 page

const AppContent = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);  // Preloader will show for 1 second on every route change
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <div className="App">
      {loading && <Preloader />}
      <Main>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/music" element={<Music />} />
          <Route path="/events/:id" element={<Events />} />
          <Route path="/events-details/:id" element={<EventsDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/events-detailsNew/:id" element={<EventsDetailsNew />} />
          <Route path="/post-story" element={<PostStory />} />
          <Route path="/my-profile" element={<Profile />} />
          <Route path="/submit-your-competition" element={<SubmitCompetition />} />
          <Route path="/companyCompetitions" element={<CompanyCompetitions />} />
          <Route path="/CompanyCompetitionsDetails/:id" element={<CompanyCompetitionsDetails />} />

          {/* Catch-all route for 404 errors */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Main>
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
