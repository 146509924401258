import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import './Profile.css';

function Profile() {
  const initialState = {
    Username: "",
    email: "",
    phoneNumber: "",
    address: "",
    state: "",
    zipCode: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [userId, setUserId] = useState(null);
  const [file, setFile] = useState("../../assets/img/avatars/14.png");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [buttonText, setButtonText] = useState("Upload new photo");

  const authToken = localStorage.getItem("token");

  useEffect(() => {
    if (!authToken) {
      toast.error("No authentication token found. Please log in.");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${Front_API_URL}/index`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUserId(response.data.user.id);
        setFormData({
          Username: response.data.user.username,
          email: response.data.user.email,
          phoneNumber: response.data.user.phone_number,
          address: response.data.user.address,
          state: response.data.user.state,
          zipCode: response.data.user.zipCode,
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data", err);
        setLoading(false);
        toast.error("Failed to fetch user data. Please try again.");
      }
    };

    fetchData();
  }, [authToken]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateFormData = () => {
    let errors = {};

    if (!formData.Username?.trim()) {
      errors.Username = "Username is required";
    }

    if (!formData.phoneNumber?.trim()) {
      errors.phoneNumber = "Phone number is required";
    }

    if (!formData.address?.trim()) {
      errors.address = "Address is required";
    }

    if (!formData.state?.trim()) {
      errors.state = "State is required";
    }

    if (!formData.zipCode?.trim()) {
      errors.zipCode = "Zip code is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFormData()) {
      try {
        const response = await axios.post(`${Front_API_URL}/updateMyProfile`, {
          ...formData,
          userId 
        }, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.status === 200) {
          toast.success(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to update profile. Please try again.");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Front_API_URL}/showImage`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const profilePic = response.data.profile_pic;
        setFile(
          profilePic
            ? `${Front_URL}/storage/images/${profilePic}`
            : "../../assets/img/avatars/14.png"
        );
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data", err);
        setLoading(false);
      }
    };

    fetchData();
  }, [authToken]);

  const imageUpload = (e) => {
    const file = e.target.files[0];
    setFile(URL.createObjectURL(file));

    setButtonText("Uploading...");
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${Front_API_URL}/uploadImage`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setButtonText("Uploaded");
        toast.success(response.data.message);
        setErrors({});
        setTimeout(() => {
          setButtonText("Upload new photo");
        }, 4000);
      })
      .catch((error) => {
        if (error.response && error.response.data.error) {
          const errorMessage = error.response.data.error.file[0];
          errors.file = errorMessage;
          setErrors(errors);
          setTimeout(() => {
            setButtonText("Upload new photo");
          }, 1000);
        } else {
          console.error("Error:", error);
          toast.error("Failed to upload image. Please try again.");
        }
      });
  };

  return (
    <>
      <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper">
                <ToastContainer />
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row">
                    <div className="col-md-12 card-top-mt-5">
                      <div className="card mb-4">
                        <div className="card-body">
                          <h4 className="">
                            <span className="fw-light text-dark">Profile Details</span>
                            <h6 className="text-muted">Fill Profile Details</h6>
                          </h4>
                          <div className="d-flex align-items-start align-items-sm-center gap-4">
                            <img
                              src={file}
                              alt="user-avatar"
                              className="d-block w-px-100 h-px-100 rounded"
                              id="uploadedAvatar"
                              style={{ objectFit: "cover" }} // Ensure the image fits within the box
                            />
                            <div className="button-wrapper">
                              <label
                                htmlFor="upload"
                                className="btn btn-primary me-2 mb-3"
                                tabIndex="0"
                              >
                                <span className="d-none d-sm-block">
                                  {buttonText}
                                </span>
                                <i className="ti ti-upload d-block d-sm-none"></i>
                                <input
                                  onChange={imageUpload}
                                  type="file"
                                  id="upload"
                                  className="account-file-input"
                                  hidden
                                  accept="image/png, image/jpeg"
                                />
                              </label>
                              {errors.file && (
                                <span className="error text-danger">{errors.file}</span>
                              )}
                              <div className="text-muted">
                                Allowed JPG, GIF or PNG. Max size of 2mb
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body">
                          <form
                            id="formAccountSettings"
                            method="POST"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label htmlFor="Username" className="form-label">
                                  Username
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="Username"
                                  name="Username"
                                  value={formData.Username}
                                  onChange={handleChange}
                                />
                                {errors.Username && (
                                  <span className="error text-danger">{errors.Username}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label htmlFor="email" className="form-label">
                                  E-mail
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="email"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  placeholder="john.doe@example.com"
                                  disabled
                                  readOnly
                                />
                                {errors.email && (
                                  <span className="error text-danger">{errors.email}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="phoneNumber">
                                  Phone Number
                                </label>
                                <div className="input-group input-group-merge">
                                  <input
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter your phone number"
                                  />
                                </div>
                                {errors.phoneNumber && (
                                  <span className="error text-danger">{errors.phoneNumber}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="address">
                                  Address
                                </label>
                                <input
                                  type="text"
                                  id="address"
                                  name="address"
                                  value={formData.address}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Enter your address"
                                />
                                {errors.address && (
                                  <span className="error text-danger">{errors.address}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="state">
                                  State
                                </label>
                                <input
                                  type="text"
                                  id="state"
                                  name="state"
                                  value={formData.state}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Enter your state"
                                />
                                {errors.state && (
                                  <span className="error text-danger">{errors.state}</span>
                                )}
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="zipCode">
                                  Zip Code
                                </label>
                                <input
                                  type="text"
                                  id="zipCode"
                                  name="zipCode"
                                  value={formData.zipCode}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="Enter your zip code"
                                />
                                {errors.zipCode && (
                                  <span className="error text-danger">{errors.zipCode}</span>
                                )}
                              </div>
                            </div>
                            <div className="mt-2">
                              <Button type="submit" className="me-2" variant="primary">
                                Save changes
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-backdrop fade"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
