import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import PostStory from "./PostStory";
import Query from "./Query";
import PostedStory from "./PostedStory";

export default function EventsDetailsNew() {
  const { id } = useParams();
  const [EventDetails, setEventDetails] = useState(null);
  const [moreEvents, setMoreEvents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/allEventsNew/${id}`);
      setEventDetails(response.data.length > 0 ? response.data[0] : null);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchmoreevents = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/moreEventsNew`);
      setMoreEvents(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchmoreevents();
  }, []);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const handleLoginRedirect = () => {
    navigate("/signin");
  };

  return (
    <>
      <div className="banner-area events-details">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{EventDetails?.title}</h2>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                    <i className="flaticon-fast-forward"></i>
                    <button
                      onClick={() => navigate(-1)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Events
                    </button>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active">Events details</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="single-event">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              {EventDetails ? (
                <div className="event-slider">
                  <div className="single-slider">
                    <div className="event-img">
                      <img
                        src={`${Front_URL}/storage/images/${EventDetails.image}`}
                        alt="event"
                      />
                    </div>
                    <div className="content">
                      <h2>
                        <a href={EventDetails.link} target="_blank" rel="noopener noreferrer">
                          {EventDetails.title}
                        </a>
                      </h2>
                      <p className="calender">
                        <i className="flaticon-calendar"></i>{" "}
                        {EventDetails.e_date}
                      </p>
                      <p>{stripHtmlTags(EventDetails.description)}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="no-data-found text-center">
                  <h3>No Data Found !</h3>
                </div>
              )}

              <div className="share">
                <ul className="share-list">
                  <li>
                    <p className="share-p">Share</p>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-5">
              <div className="right-content">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control about-search"
                      placeholder="Search..."
                    />
                  </div>
                  <button type="submit" disabled>
                    {" "}
                    <i className="flaticon-search"></i>
                  </button>
                </form>

                <p className="visit">More Events</p>

                {moreEvents.length > 0 ? (
                  moreEvents.map((event) => (
                    <div className="single-content" key={event.id}>
                      <div className="content-img">
                        <Link to={`/events-detailsNew/${event.id}`}>
                          <img
                            src={`${Front_URL}/storage/images/${event.image}`}
                            alt="blog"
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <Link to={`/events-detailsNew/${event.id}`}>
                          <h2>{event.title}</h2>
                        </Link>
                        <p className="calender">
                          <i className="flaticon-calendar"></i>{" "}
                          {event.e_date}
                        </p>
                        <p>
                          {truncateText(
                            stripHtmlTags(event.description),
                            106
                          )}
                        </p>
                        <Link
                          to={`/events-detailsNew/${event.id}`}
                          className="line-bnt"
                        >
                          Read More <i className="flaticon-next"></i>
                        </Link>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data-found text-center">
                    <h3>No More Events Found</h3>
                    <p>It looks like there are no more events available. Please check back later.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Conditionally render PostStory based on login status */}
      <PostedStory eventId={id} pageName="EventDetails" />

      {isLoggedIn ? (
        <PostStory pageName="EventDetails" eventId={id} />
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',  // Center horizontally
          alignItems: 'center',      // Center vertically
          height: '100vh',           // Full viewport height for vertical centering
        }}>
          <button
            onClick={handleLoginRedirect}
            style={{
              backgroundColor: '#007bff', // Blue background
              color: '#fff',              // White text
              border: 'none',             // No border
              padding: '10px 20px',       // Padding
              fontSize: '16px',           // Font size
              borderRadius: '5px',        // Rounded corners
              cursor: 'pointer',          // Pointer cursor on hover
              textAlign: 'center',        // Centered text
              textDecoration: 'none',     // No underline
              display: 'inline-block',    // Inline-block display
              transition: 'background-color 0.3s ease', // Smooth background color transition
            }}
            className="login-button"
          >
            Post a Story
          </button>
        </div>
      )}

      {/* <Query /> */}
    </>
  );
}
