import axios from "axios";
import { Front_API_URL } from "../Front/Constants";
import { Front_URL } from "../Front/constantsurl";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './visitors.css'; // Import the CSS file for styling

export default function Footer() {
    const [category, setCategory] = useState([]);
    const [visitorCount, setVisitorCount] = useState(4000); // New state for visitor count

    const [formData, setFormData] = useState({
        title: "",
        address: "",
        helpCenter: "",
        whatsappNumber: "",
        email: "",
        facebookUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        logo: "",
    });

    const fetchCategory = async () => {
        try {
            const response = await axios.get(`${Front_API_URL}/category`);
            console.log("Category Data:", response.data);
            setCategory(response.data);
        } catch (error) {
            console.error("Error fetching category data", error);
        }
    };

    const fetchCompanySettings = async () => {
        try {
            const response = await axios.get(`${Front_API_URL}/frontShowComapanySetting`);
            console.log("Company Settings Data:", response.data);
            setFormData({
                title: response.data.data.title,
                address: response.data.data.address,
                helpCenter: response.data.data.help_center,
                whatsappNumber: response.data.data.whatspp_num,
                email: response.data.data.email_support,
                facebookUrl: response.data.data.facebook,
                twitterUrl: response.data.data.twitter,
                instagramUrl: response.data.data.instagram,
                logo: response.data.data.logo,
            });
        } catch (err) {
            console.error("Error fetching company settings data", err);
        }
    };

    useEffect(() => {
        fetchCategory();
        fetchCompanySettings();
    }, []);

    return (
        <>
            {/* <!-- Footer Area --> */}
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-left">
                                <a href="/" className="logo">
                                    <img src={`${Front_URL}/storage/images/talentpedia-whitelogo.png`} alt="logo" />
                                </a>
                                <span
                                  style={{
                                    marginTop: '8px', // Adjust space between logo and text
                                    fontSize: '16px', // Adjust text size
                                    fontWeight: 'bold', // Optional: Make text bold
                                    color: '#fff', // Adjust color if needed
                                  }}
                                >
                                  Talentpedia
                                </span>
                                <p>
                                    Talentpedia is a comprehensive resource dedicated to various aspects of talent development, including educational competitions.
                                </p>
                                <ul className="footer-social">
                                    <li>
                                        <a href={formData.facebookUrl} target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={formData.twitterUrl} target="_blank" rel="noopener noreferrer">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${formData.email}`}><i className="fas fa-envelope"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="footer-content fml-15 fml-20">
                                <h2>Quick Links</h2>
                                <ul>
                                    <li>
                                        <Link to="/"><i className="fas fa-chevron-right"></i>Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us"><i className="fas fa-chevron-right"></i>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs"><i className="fas fa-chevron-right"></i>Inspirational Stories</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact"><i className="fas fa-chevron-right"></i>Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/faq"><i className="fas fa-chevron-right"></i>FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="footer-content fml-15 fml-20">
                                <h2>Find Us</h2>
                                <ul>
                                    {/* <li>
                                        <a href={`tel:${formData.whatsappNumber}`}><i className="fas fa-phone"></i>{formData.whatsappNumber}</a>
                                    </li> */}
                                    <li>
                                        <a href={`tel:${formData.helpCenter}`}><i className="fas fa-headset" style={{ fontSize: '15px' }}></i> {formData.helpCenter}</a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${formData.email}`}><i className="fas fa-envelope" style={{ fontSize: '15px' }}></i> {formData.email}</a>
                                    </li>
                                    <li>
                                        <a><i class="fas flaticon-clock" style={{ fontSize: '15px' }}></i>  Mon - Sat (9:00 am - 6:00 pm)</a>
                                    </li>
                                   
                                    {/* <li>
                                        <a href="#"><i className="fas fa-map-marker-alt"></i>{formData.address}</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Footer Area --> */}


            {/* <!-- Copy Area --> */}

            <div className="copy-area">
                <div className="container">
                    <div className="row">
                      
                        <div className="col-lg-6 text-right">
                            <div className="copy">
                                <p>
                                    Copyright @Talentpedia. All Rights Reserved by
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        Talentpedia
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div class="my-counter">
    <div class="box">
      <span id="counter" data-start="100" data-end="500" data-duration="1500">{visitorCount}+</span>
       &nbsp;<span className="text-light">Visitors!</span>
    </div>
    </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="copy-area">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="copy">
                                <p>
                                    Copyright @Talentpedia. All Rights Reserved by
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        Talentpedia
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- End Copy Area --> */}

            {/* <!-- Scroll top --> */}
            <a href="#" className="scroll-top wow animate__animated animate__bounceInDown">
                <i className="fas fa-angle-double-up"></i>
            </a>
        </>
    );
}
