import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Query from "./Query";
import "react-toastify/dist/ReactToastify.css";
export default function AboutUs() {
 

  return (
    <>
      {/* <!-- Start Page Title Area --> */}
      <ToastContainer />
      <div className="banner-area about">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
             
              <div className="page-title-content">
                <h2>About Us</h2>
                <ul>
                  <li>
                    <a href="index.html"> Home </a>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active"> About </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- About Area --> */}
      <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-tittle">
                <h2>
                  Welcome to<span> Talentpedia</span>
                </h2>
                <p>
                  Competitions are dynamic events designed to engage students in
                  various fields of interest, encouraging them to develop their
                  skills, knowledge, and talents. These competitions span across
                  a multitude of disciplines, each offering unique opportunities
                  for students to explore their passions, challenge themselves,
                  and gain recognition for their efforts.
                </p>
                <p>
                  Music competitions and drama festivals allow students to
                  showcase their performing arts talents. In music competitions,
                  solo performers or ensembles present pieces that demonstrate
                  their technical skills and emotional expression. Drama
                  festivals, on the other hand, involve acting, directing, and
                  stage production, offering students a platform to explore
                  theatrical arts. These events emphasize teamwork, creativity,
                  and the power of performance to convey stories and emotions.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-side">
                <img src="./assets/images/about-side.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Area --> */}

      {/* <!-- Choose area  --> */}
      <section className="choose-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 ps-0">
              <div className="home-choose-img">
                <img
                  src="assets/images/whychoose.png
                    "
                  alt="choose"
                />
              </div>
            </div>

            <div className="col-lg-6 home-choose">
              <div className="home-choose-content">
                <div className="section-tittle">
                  <h2>
                    Why Choose <span> Talentpedia</span>
                  </h2>
                  <p>
                    School choice theory assumes that parents are rational
                    actors that can gather and consume information to find a
                    school that matches their child's needs. Parents' desires
                    and ability to choose quality schools.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-sm-12 col-md-5">
                    <ul className="choose-list-left">
                      <li>
                        <i className="flaticon-check-mark"></i>Top 10 Rated
                        School
                      </li>
                      <li>
                        {" "}
                        <i className="flaticon-check-mark"></i>Great Behaviour
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Helpful &
                        Kindnesss
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Learning With Fun
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Children Safety
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-8 col-sm-12 col-md-7">
                    <div className="choose-list-home">
                      <ul>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Eco Freindly
                          Environment
                        </li>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Healthy Food &
                          Water in Canteen
                        </li>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Health Care
                          With Certified Dorctors
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>Huge Playground
                          With Children Park
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>Physically Fit
                          Buses With Experienced Driver
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a href="" className="box-btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Choose area --> */}

      {/* <!-- Contact Area --> */}
      {/* <Query /> */}
      
      {/* <!-- End Contact Area --> */}
    </>
  );
}
