import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Query from "./Query";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import PostStory from "./PostStory";
import PostedStory from "./PostedStory";

export default function EventsDetails() {
  const { id } = useParams();
  const [EventDetails, setEventDetails] = useState([]);
  const [moreEvents, setMoreEvents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    setIsLoggedIn(loggedIn);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${Front_API_URL}/frontcompetitionDetails/${id}`
      );
      setEventDetails(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchMoreEvents = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/moreEvents/${id}`);
      setMoreEvents(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMoreEvents();
  }, [id]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const handleLoginRedirect = () => {
    navigate("/signin");
  };

  return (
    <>
      {/* Start Page Title Area */}
      <div className="banner-area events-details">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{EventDetails.title} 5 April</h2>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                    <i className="flaticon-fast-forward"></i>
                    <Link
                      onClick={() => navigate(-1)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Competition
                    </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active">Competition details</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}

      {/* Events */}
      <section className="single-event">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="event-slider">
                <div className="single-slider">
                  <div className="event-img">
                    <img
                      src={`${Front_URL}/storage/images/${EventDetails.image}`}
                      alt="event"
                    />
                  </div>
                  <div className="content">
                    <h2>
                    <a href={EventDetails.link} target="_blank" rel="noopener noreferrer">
          {EventDetails.title}
        </a>
                    </h2>
                    <div className="competition-details">
                                                    <h5><span>Mode:</span> {EventDetails.mode === 1 ? 'Online' : EventDetails.mode === 2 ? 'Offline' : ''} </h5>
                                                    <h5><span>Country:</span> {EventDetails.countryname} </h5>
                                                    <h5><span>Age Group:</span> {EventDetails.age_group}</h5>
                                                </div>
                    <p>{stripHtmlTags(EventDetails.description)}</p>
                  </div>
                </div>
              </div>
              <div className="share">
                <ul className="share-list">
                  <li>
                    <p className="share-p">Share</p>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-5">
              <div className="right-content">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control about-search"
                      placeholder="Search..."
                    />
                  </div>
                  <button type="submit" disabled>
                    {" "}
                    <i className="flaticon-search"></i>
                  </button>
                </form>

                <p className="visit">More Competition</p>

                {moreEvents.map((event) => (
                  <div className="single-content" key={event.c_id}>
                    <div className="content-img">
                      <Link to={`/events-details/${event.c_id}`}>
                        <img
                          src={`${Front_URL}/storage/images/${event.image}`}
                          alt="blog"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <Link to={`/events-details/${event.c_id}`}>
                        <h2>{event.title}</h2>
                      </Link>
                      <p className="calender">
                        <i className="flaticon-calendar"></i>{" "}
                        {event.comp_date}
                      </p>
                    
                      <div className="competition-details-r">
                      <h5><span>Mode:</span> {event.mode === 1 ? 'Online' : event.mode === 2 ? 'Offline' : ''} </h5>
                      <h5><span>Country:</span> {event.countryname} </h5>
                      <h5><span>Age Group:</span> {event.age_group}</h5>
                                                </div>

                                                
                      <p>
                        {truncateText(
                          stripHtmlTags(event.description),
                          106
                        )}
                      </p>
                      {/* <div dangerouslySetInnerHTML={{ __html: event.description }} /> */}

                      <Link
                        to={`/events-details/${event.c_id}`}
                        className="line-bnt"
                      >
                        Read More <i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                ))}
                <Link to={`/events/${EventDetails.id}`} className="box-btn">
                  More Competition
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PostedStory eventId={id} pageName="CompetitionDetails"/>

      {/* Conditionally render PostStory or a Login button */}
      {isLoggedIn ? (
        <PostStory pageName="CompetitionDetails" eventId={id} />
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',  // Center horizontally
          alignItems: 'center',      // Center vertically         // Full viewport height for vertical centering
        }}>
          <button
  onClick={handleLoginRedirect}
  style={{
    backgroundColor: '#007bff', // Blue background
    color: '#fff',              // White text
    border: 'none',             // No border
    padding: '10px 20px',       // Padding
    fontSize: '16px',           // Font size
    borderRadius: '5px',        // Rounded corners
    cursor: 'pointer',          // Pointer cursor on hover
    textAlign: 'center',        // Centered text
    textDecoration: 'none',     // No underline
    display: 'inline-block',    // Inline-block display
    transition: 'background-color 0.3s ease', // Smooth background color transition
  }}
  className="login-button"
>
  Post a Story
</button>

        </div>
      )}
      

      {/* <Query /> */}
    </>
  );
}
