import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Query from "./Query";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";

export default function CompanyCompetitionsDetails() {
  const { id } = useParams();
  const [CompanyCompetitionDetails, setCompanyCompetitionDetails] = useState({});
  const [moreCompanyCompetitions, setMoreCompanyCompetitions] = useState([]);
  const navigate = useNavigate();

  const fetchCompanyCompetitionDetails = async () => {
    try {
      const response = await axios.get(
        `${Front_API_URL}/CompanyCompetitionDetails/${id}`

      );
      setCompanyCompetitionDetails(response.data);
    } catch (error) {
      console.error("Error fetching competition details", error);
    }
  };
  const truncateString = (str, num) => {
    return str.length > num ? str.slice(0, num) + '...' : str;
};
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};
  const fetchMoreCompanyCompetitions = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/moreCompanyCompetition`);
      setMoreCompanyCompetitions(response.data.data); // Adjusting to use the data from the pagination response
    } catch (error) {
      console.error("Error fetching more competitions", error);
    }
  };

  useEffect(() => {
    fetchCompanyCompetitionDetails();
    fetchMoreCompanyCompetitions();
  }, [id]);
  

  return (
    <>
      {/* Start Page Title Area */}
      <div className="banner-area events-details">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>{CompanyCompetitionDetails.heading}</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                    <i className="flaticon-fast-forward"></i>
                    <Link
                      onClick={() => navigate(-1)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Competitions
                    </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active">{CompanyCompetitionDetails.title}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}

      {/* CompanyCompetition */}
      <section className="single-event">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="event-slider">
                <div className="single-slider">
                  <div className="event-img">
                    <img
                      src={`${Front_URL}/storage/images/company/${CompanyCompetitionDetails.competition_image	}`}
                      alt="company" style={{ width: "100%", height: "auto" }}
                    />
                  </div>

                  


                  <div className="content company-comp">
                    <h2>{CompanyCompetitionDetails.title}</h2>
                    <div className="competition-details-r">
                      <h5><span>Organiser:</span>  {(CompanyCompetitionDetails.organiser_name)} </h5>
                      <h5><span>Prize Type:</span> {CompanyCompetitionDetails.prize_type} </h5>
                      <h5><span>Regions:</span> {CompanyCompetitionDetails.regions ? JSON.parse(CompanyCompetitionDetails.regions).join(", ") : ""}</h5>
                      <h5><span>Education:</span> {CompanyCompetitionDetails.education ? JSON.parse(CompanyCompetitionDetails.education).join(", ") : ""}</h5>
                      <h5><span>Category:</span> {CompanyCompetitionDetails.category ? JSON.parse(CompanyCompetitionDetails.category).join(", ") : ""}</h5>
                      <h5><span>Prize Summary:</span> {(CompanyCompetitionDetails.prize_summary)}</h5>
                    </div>
                    {/* <p>
                        <span>Organiser: </span>  {(CompanyCompetitionDetails.organiser_name	)}
                      </p>
                      <p>
                        <span> Prize Type: </span>  {(CompanyCompetitionDetails.prize_type)}
                      </p>
                    
                   
                      <p>
                      <span>Regions:</span>  {CompanyCompetitionDetails.regions ? JSON.parse(CompanyCompetitionDetails.regions).join(", ") : ""}
                      </p>
                      <p>
                      <span>Education:</span>{CompanyCompetitionDetails.education ? JSON.parse(CompanyCompetitionDetails.education).join(", ") : ""}
                      </p>
                      <p>
                    <span>Category: </span> {CompanyCompetitionDetails.category ? JSON.parse(CompanyCompetitionDetails.category).join(", ") : ""}
                      </p> */}
                         {/* <p>
                      <span>Prize Summary: </span> {(CompanyCompetitionDetails.prize_summary)}
                      </p> */}
                      <p>{stripHtmlTags(CompanyCompetitionDetails.description)}</p>
                  </div>
                </div>
              </div>
              <div className="share">
                <ul className="share-list">
                  <li>
                    <p className="share-p">Share</p>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i className="flaticon-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-5">
              <div className="right-content">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control about-search"
                      placeholder="Search..."
                    />
                  </div>
                  <button type="submit" disabled>
                    <i className="flaticon-search"></i>
                  </button>
                </form>

                <p className="visit">More Competitions</p>

                {moreCompanyCompetitions.map((CompanyCompetition) => (
                  <div className="single-content" key={CompanyCompetition.id}>
                    <div className="content-img">
                      <Link to={`/CompanyCompetitionsDetails/${CompanyCompetition.id}`}>
                        <img src={`${Front_URL}/storage/images/company/${CompanyCompetition.competition_image	}`} alt="company" />
                      </Link>
                    </div>
                    <div className="content">
                      <Link to={`/CompanyCompetitionsDetails/${CompanyCompetition.id}`}>
                        <h2>{CompanyCompetition.title}</h2>
                      </Link>
                      <p className="calender">
                        <i className="flaticon-calendar"></i> {CompanyCompetition.competition_deadline}
                      </p>
                      <p>
                        Organiser:  {(CompanyCompetition.organiser_name	)}
                      </p>
                      <p>{truncateString(stripHtmlTags(CompanyCompetition.description), 106)}</p>
                      <Link to={`/CompanyCompetitionsDetails/${CompanyCompetition.id}`} className="line-bnt">
                        Read More <i className="flaticon-next"></i>
                      </Link>
                    </div>
                  </div>
                ))}

                <Link to="/CompanyCompetitions" className="box-btn">
                  More CompanyCompetitions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End CompanyCompetition */}

      {/* Contact Area */}

      {/* <Query /> */}
      {/* End Contact Area */}
    </>
  );
}
