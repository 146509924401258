import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";

export default function CompanyCompetitions() {
    const [current_page, setCurrent_page] = useState(1);
    const [links, setLinks] = useState({});
    const [CompanyCompetitions, setCompanyCompetitions] = useState([]);

    const fetchData = async (page) => {
        try {
            const response = await axios.get(`${Front_API_URL}/allCompanyCompetition?page=${page}`);
            setCompanyCompetitions(response.data.data);
            setCurrent_page(response.data.current_page);
            setLinks(response.data.links);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData(current_page);
    }, [current_page]);

    const handlePagination = (url) => {
        if (url) {
            const page = new URL(url).searchParams.get('page');
            setCurrent_page(Number(page));
        }
    };

    const truncateString = (str, num) => {
        return str.length > num ? str.slice(0, num) + '...' : str;
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return null; // or return an empty string ''
        }
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };
    return (
        <>
            <div className="banner-area events-bg">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Competitions</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <i className="flaticon-fast-forward"></i>
                                        <p className="active">Competitions</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="events">
                <div className="container">
                    <div className="row">
                        {CompanyCompetitions.length > 0 ? (
                            CompanyCompetitions.map((item, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="single-events">
                                        <div className="events-img">
                                            <Link to={`/CompanyCompetitionsDetails/${item.id}`}>
                                                <img src={`${Front_URL}/storage/images/company/${item.competition_image}`} alt="competitions"  />
                                            </Link>
                                        </div>
                                        <div className="content company-comp">
                                            <Link to={item.competition_url}>
                                                <h2>{item.title}</h2>
                                            </Link>
                                            <p className="">
                        <i className="flaticon-calendar"></i>{" "}
                        {formatDate(item.competition_deadline)}
                      </p>

                      <div className="competition-details-r">
                      <h5><span>Organiser:</span>  {(item.organiser_name)} </h5>
                      <h5><span>Prize Type:</span> {item.prize_type} </h5>
                      <h5><span>Regions:</span> {item.regions ? JSON.parse(item.regions).join(", ") : ""}</h5>
                      <h5><span>Education:</span> {item.education ? JSON.parse(item.education).join(", ") : ""}</h5>
                      <h5><span>Category:</span> {item.category ? JSON.parse(item.category).join(", ") : ""}</h5>
                                                </div>




                      
                      {/* <p>
                        <span>Organiser: </span>  {(item.organiser_name	)}
                      </p>
                      <p>
                        <span> Prize Type: </span>  {(item.prize_type)}
                      </p>
                     */}
                      {/* <p>
                      <spa.n>Prize Summary: </span> {(item.prize_summary)}
                      </p> .*/}
                      {/* <p>
                      <span>Regions:</span>  {item.regions ? JSON.parse(item.regions).join(", ") : ""}
                      </p> */}
                      {/* <p>
                      <span>Education:</span>{item.education ? JSON.parse(item.education).join(", ") : ""}
                      </p>
                      <p>
                    <span>Category: </span> {item.category ? JSON.parse(item.category).join(", ") : ""}
                      </p> */}
                                            <p className="event-description">
                                                {truncateString(stripHtmlTags(item.description), 106)}
                                            </p>
                                            <Link to={`/CompanyCompetitionsDetails/${item.id}`} className="line-bnt">Read More <i className="flaticon-next"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-data-found text-center">
                                <h3>No Competitions Found</h3>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <ul className="pagination">
                                {links.prev && (
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            onClick={() => handlePagination(links.prev)}
                                        >
                                            {"<"}
                                        </a>
                                    </li>
                                )}
                                {links.next && (
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            onClick={() => handlePagination(links.next)}
                                        >
                                            {">"}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
