import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Front_API_URL } from "./Front/Constants";
import './ChangePasswordModal.css';

const ChangePasswordModal = ({ show, handleClose }) => {
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new_password !== confirm_password) {
      setError("New password and confirm password do not match");
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Or get token from another secure source
      await axios.post(
        `${Front_API_URL}/updatePassword`,
        { current_password, new_password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("Password updated successfully. Please log in with your new password.");
      setError(""); // Clear any existing errors

      // Close the modal and redirect after a short delay
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("loggedIn"); // Remove loggedIn status
        window.location.reload(true);

        handleClose(); // Close modal
        navigate("/signin"); // Redirect to login page
      }, 2000); // Wait for 2 seconds before redirect
    } catch (error) {
      setError("An error occurred while updating the password.");
      setSuccess(""); // Clear success message if there's an error
      console.error("Error updating password", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        {success && <p className="text-success">{success}</p>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="current_password">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              type="password"
              value={current_password}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="new_password">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={new_password}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="confirm_password">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Update Password
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
