import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";

export default function SignUp() {
  const initialState = {
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    is_admin: 0,
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.username.trim()) {
      errors.username = "Name is required";
    } else if (formData.username.length < 3) {
      errors.username = "Name must be at least 3 characters long";
    } else if (formData.username.length > 50) {
      errors.username = "Name must be less than 50 characters long";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.username)) {
      errors.username = "Name can only contain letters and spaces";
    } else if (/^\s|\s$/.test(formData.username)) {
      errors.username = "Name cannot start or end with whitespace";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!formData.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    } else if (formData.password !== formData.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(`${Front_API_URL}/register`, formData);
        setFormData(initialState);
        setAlertMessage(response.data.message);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      } catch (error) {
        if (error.response) {
          console.log(error.response)
          if (error.response.status === 409) {
            // Handle conflict error specifically
            setAlertMessage("This email or username is already taken. Please choose another.");
            setShowAlert(true);
          } else if (error.response.data.error) {
            setErrors(error.response.data.error);
          }
        } else {
          console.error("Error:", error);
        }
      }
    }
  };

  return (
    <>
      {/* <!-- Start Page Title Area --> */}
      <div className="banner-area signup">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Sign Up</h2>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active"> Sign Up </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- SignUp --> */}
      <section className="signup-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="sign-up-img">
                <img src="assets/images/signup.svg" alt="signup" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="signup-form">
                <h2>Create your Account</h2>
                <form
                  id="formAuthentication"
                  className="mb-3"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.username ? "is-invalid" : ""}`}
                          placeholder="User name"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                        />
                        {errors.username && (
                          <div className="invalid-feedback">{errors.username}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className={`form-control ${errors.email ? "is-invalid" : ""}`}
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className={`form-control ${errors.password ? "is-invalid" : ""}`}
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        {errors.password && (
                          <div className="invalid-feedback">{errors.password}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className={`form-control ${errors.confirm_password ? "is-invalid" : ""}`}
                          placeholder="Confirm Password"
                          name="confirm_password"
                          value={formData.confirm_password}
                          onChange={handleChange}
                        />
                        {errors.confirm_password && (
                          <div className="invalid-feedback">{errors.confirm_password}</div>
                        )}
                      </div>
                    </div>

                    <input type="hidden" name="is_admin" value={formData.is_admin} />

                    <div className="col-lg-12">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="checkme" />
                        <label className="form-check-label" htmlFor="checkme">
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <button type="submit" className="box-btn">
                        Sign Up
                      </button>
                    </div>
                    <span className="already">
                      Have already an account? <Link to="/signin">Sign In!</Link>
                    </span>
                  </div>
                </form>
                {showAlert && <div className="alert alert-success">{alertMessage}</div>}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End SignUp --> */}
    </>
  );
}
