import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Events() {
    const { id } = useParams();  // Get competition ID from URL params
    const [current_page, setCurrent_page] = useState(1);  // Track the current page
    const [links, setLinks] = useState([]);  // Store pagination links
    const [events, setEvents] = useState([]);  // Store fetched events

    // States for filters
    const [selectedCountry, setSelectedCountry] = useState("");  // Selected country
    const [selectedMode, setSelectedMode] = useState("");  // Selected mode
    const [selectedAgeGroup, setSelectedAgeGroup] = useState("");  // Selected age group
    const [countryList, setCountryList] = useState([]);  // List of countries

    // Fetch events
    const fetchData = async (page) => {
        try {
            const response = await axios.get(
                `${Front_API_URL}/allEvents/${id}?page=${page || current_page}&country=${selectedCountry}&mode=${selectedMode}&age_group=${selectedAgeGroup}`
            );
            setEvents(response.data.data);  // Set fetched events
            setCurrent_page(response.data.current_page);  // Update current page
            setLinks(response.data.links);  // Set pagination links
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    // Fetch list of countries
    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${Front_API_URL}/countries`);
            setCountryList(response.data);  // Set country list from API response
        } catch (error) {
            console.error("Error fetching countries", error);
        }
    };

    // UseEffect to fetch countries on component mount
    useEffect(() => {
        fetchCountries();
    }, []);  // Empty dependency array ensures it runs only once

    // UseEffect to fetch events whenever current page, selectedCountry, selectedMode, or selectedAgeGroup changes
    useEffect(() => {
        fetchData(current_page);
    }, [current_page, id, selectedCountry, selectedMode, selectedAgeGroup]);

    // Pagination logic
    const pagination = (url) => {
        if (url) {
            const page = new URL(url).searchParams.get('page');
            setCurrent_page(Number(page));
        }
    };

    // Truncate string utility to limit text length
    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    // Utility to remove HTML tags from event descriptions
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    // Utility to format the competition date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return null;
        }
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <>
            {/* Page Title Area */}
            <div className="banner-area events-bg">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Our Competitions</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <i className="flaticon-fast-forward"></i>
                                        <p className="active">Competitions</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Title Area */}

            {/* Filters Section */}
            <div className="container">
                <div className="row my-4">
                    <div className="col-md-4">
                        <label>Select Country</label>
                        <select
                            className="form-control"
                            value={selectedCountry}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                        >
                            <option value="">Select Country</option>
                            {countryList.map((country, index) => (
                                <option key={index} value={country.id}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-4">
                        <label>Select Mode</label>
                        <select
                            className="form-control"
                            value={selectedMode}
                            onChange={(e) => setSelectedMode(e.target.value)}
                        >
                            <option value="">Select Mode</option>
                            <option value="1">Online</option>
                            <option value="2">Offline</option>
                        </select>
                    </div>

                    <div className="col-md-4">
                        <label>Select Age Group</label>
                        <select
                            className="form-control"
                            value={selectedAgeGroup}
                            onChange={(e) => setSelectedAgeGroup(e.target.value)}
                        >
                              <option value="" >Select age group</option>
                              <option value="Children">Children</option>
                              <option value="Teenagers">Teenagers</option>
                              <option value="Adults">Adults</option>
                              <option value="Seniors">Seniors</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Events Section */}
            <section className="events">
                <div className="container">
                    {events.length === 0 ? (
                        <div className="no-data-found text-center">
                            <h3>No Data Found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="row">
                                {events.map((item, index) => (
                                    <div className="col-lg-4 col-md-6" key={index}>
                                        <div className="single-events">
                                            <div className="events-img">
                                                <Link to={`/events-details/${item.c_id}`}>
                                                    <img src={`${Front_URL}/storage/images/${item.image}`} alt="competitions" />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <Link to={`/events-details/${item.c_id}`} className="hei-55">
                                                    <h2>{item.title}</h2>
                                                </Link>

                                                <p className="calender">
                                                    <i className="flaticon-calendar"></i>{" "}
                                                    {formatDate(item.comp_date)}
                                                </p>

                                                <div className="competition-details-r">
                                                    <h5><span>Mode:</span> {item.mode === 1 ? 'Online' : 'Offline'}</h5>
                                                    <h5><span>Country:</span> {item.countryname}</h5>
                                                    <h5><span>Age Group:</span> {item.age_group}</h5>
                                                </div>

                                                <p className="event-description">
                                                    {truncateString(stripHtmlTags(item.description), 106)}
                                                </p>

                                                <Link to={`/events-details/${item.c_id}`} className="line-bnt">
                                                    Read More <i className="flaticon-next"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Pagination Controls */}
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <ul className="pagination">
                                        {links && links.map((item, index) => {
                                            let label = index === 0 ? "<" : (index === links.length - 1 ? ">" : index);
                                            return (
                                                <li key={index} className={`page-item ${item.active ? 'active' : ''}`}>
                                                    <a
                                                        className="page-link waves-effect"
                                                        onClick={() => pagination(item.url)}
                                                    >
                                                        {label}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
            {/* End Events Section */}
        </>
    );
}
