import axios from "axios";
import { Front_API_URL } from "./Front/Constants";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TiEye, TiEyeOutline } from "react-icons/ti";

export default function Signin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    let errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {

      axios
        .post(`${Front_API_URL}/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("loggedIn", true);
            localStorage.setItem("email", response.data.user.email);
            localStorage.setItem("userProfileImage", response.data.user.profile_pic);
            localStorage.setItem("userId", response.data.user.id);

            navigate("/", { replace: true });
            window.location.reload(); // Force refresh
          } else {
            setErrors({ form: response.data.message });
            setShowAlert(true);
            setAlertMessage(response.data.message);
            setTimeout(() => {
              setShowAlert(false);
            }, 5000);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertMessage("Email or password does not match");
          setTimeout(() => {
            setShowAlert(false);
          }, 5000);
        });
    }
  };

  return (
    <>
      {/* <!-- Start Page Title Area --> */}
      <div className="banner-area signup">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Sign In</h2>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active"> Sign In </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- SignUp --> */}
      <section className="signup-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="signup-form">
                <h2>Welcome Back!</h2>
                <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${errors.email ? "is-invalid" : ""}`}
                          placeholder="User name"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control ${errors.password ? "is-invalid" : ""}`}
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <div className="input-group-append" onClick={toggleShowPassword}>
                            <span className="input-group-text">
                              {showPassword ? <TiEyeOutline /> : <TiEye />}
                            </span>
                          </div>
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                      </div>
                      <div className="form-group text-left mt-3">
                        <Link to="/forgot-password" className="text-muted">Forgot Password?</Link>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <button type="submit" className="box-btn">
                        Sign In
                      </button>
                    </div>
                    
                    <span className="already">
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                    </span>
                  </div>
                </form>
                {showAlert && <div className="alert alert-danger">{alertMessage}</div>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sign-up-img">
                <img src="assets/images/signup.svg" alt="signup" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End SignUp --> */}
    </>
  );
}
