import React, { useState } from 'react';
import axios from 'axios';
import { Front_API_URL } from "./Front/Constants";

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TiEye, TiEyeOutline } from 'react-icons/ti';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
    const [errors, setErrors] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [searchParams] = useSearchParams();

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowPasswordConfirmation = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const validateForm = () => {
        let errors = {};
        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }
        if (!passwordConfirmation) {
            errors.passwordConfirmation = 'Password confirmation is required';
        } else if (password !== passwordConfirmation) {
            errors.passwordConfirmation = 'Passwords do not match';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            const email = searchParams.get('email');
            const token = searchParams.get('token');
            try {
                
                const response = await axios.post(`${Front_API_URL}/reset-password`, {
                    email,
                    token,
                    password,
                    password_confirmation: passwordConfirmation,
                });

                setAlertMessage(response.data.message);
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    navigate('/login');
                }, 50000);
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Failed to reset password. Please try again.';
                setAlertMessage(errorMessage);
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 50000);
            }
        }
    };

    return (
        <>
            <div className="banner-area signup">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Reset Password</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <i className="flaticon-fast-forward"></i>
                                        <p className="active"> Reset Password </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="signup-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="signup-form">
                                <h2>Reset Your Password</h2>
                                <form id="formAuthentication" className="mb-3" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                        placeholder="New Password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <div className="input-group-append" onClick={toggleShowPassword}>
                                                        <span className="input-group-text">
                                                            {showPassword ? <TiEyeOutline /> : <TiEye />}
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <input
                                                        type={showPasswordConfirmation ? 'text' : 'password'}
                                                        className={`form-control ${errors.passwordConfirmation ? 'is-invalid' : ''}`}
                                                        placeholder="Confirm New Password"
                                                        value={passwordConfirmation}
                                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                    />
                                                    <div className="input-group-append" onClick={toggleShowPasswordConfirmation}>
                                                        <span className="input-group-text">
                                                            {showPasswordConfirmation ? <TiEyeOutline /> : <TiEye />}
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors.passwordConfirmation && <div className="invalid-feedback">{errors.passwordConfirmation}</div>}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="box-btn">
                                                Reset Password
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {showAlert && <div className="alert alert-danger">{alertMessage}</div>}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="sign-up-img">
                                <img src="assets/images/signup.svg" alt="signup" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResetPassword;
