import React, { useState } from "react";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../PostedStory.css";

export default function PostStory({ pageName, eventId }) {
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    title: "",
    description: "",
    image: null,
  });

  const handleInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // const handleImageUpload = (e) => {
  //   setForm({
  //     ...form,
  //     image: e.target.files[0],
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const formData = new FormData();
      formData.append("title", form.title);
      formData.append("description", form.description);
      // formData.append("image", form.image);
      formData.append("pageName", pageName); // Add pageName to the form data
      formData.append("eventId", eventId);   // Add eventId to the form data
      const userId = localStorage.getItem("userId");
      formData.append("userId", userId);   // Add eventId to the form data

      try {
        axios
          .post(`${Front_API_URL}/submitStory`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            e.target.reset();
            toast.success("Story submitted successfully!");
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const validateFormData = () => {
    let errors = {};

    if (!form.title.trim()) {
      errors.title = "Title is required";
    }
    if (!form.description.trim()) {
      errors.description = "Description is required";
    }
    // if (!form.image) {
    //   errors.image = "Image is required";
    // }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <ToastContainer />

      <section className="home-contact-area pb-100 pt-100">
        <div className="container story-block">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <h2>Share Your Story with Us</h2>
              <p>We would love to hear from you. Share your experiences, thoughts, and stories with us.</p>
            </div>

            <div className="col-lg-12">
              <div className="home-contact-content event_form">
                <form id="contactForm" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          onChange={handleInput}
                          value={form.heading}
                          className="form-control"
                          placeholder="Title"
                        />
                        {errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="description"
                          className="form-control"
                          id="description"
                          onChange={handleInput}
                          value={form.description}
                          cols="30"
                          rows="5"
                          placeholder="Description"
                        ></textarea>
                        {errors.description && <span style={{ color: "red" }}>{errors.description}</span>}
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="file"
                          name="image"
                          id="image"
                          onChange={handleImageUpload}
                          className="form-control"
                        />
                        {errors.image && <span style={{ color: "red" }}>{errors.image}</span>}
                      </div>
                    </div> */}

                    <div className="col-lg-12 text-center">
                      <button className="default-btn page-btn box-btn">
                        Submit Story
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
