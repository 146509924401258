import React, { useState } from "react";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";

import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateForm = () => {
    let errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post(`${Front_API_URL}/forgot-password`, { email })
        .then((response) => {
          setShowAlert(true);
          setAlertMessage("Password reset link has been sent to your email.");
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertMessage("Failed to send password reset link. Please try again.");
        });
    }
  };

  return (
    <>
      <div className="banner-area forgot-password">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Forgot Password</h2>
                <ul>
                  <li>
                    <Link to="/"> Home </Link>
                    <i className="flaticon-fast-forward"></i>
                    <p className="active"> Forgot Password </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="forgot-password-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="forgot-password-form">
                <h2>Reset Your Password</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      placeholder="Email Address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  <div className="col-lg-12">
                    <button type="submit" className="box-btn">
                      Send Reset Link
                    </button>
                  </div>
                </form>
                {showAlert && <div className="alert alert-info mt-3">{alertMessage}</div>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="forgot-password-img">
                <img src="assets/images/forgot-password.svg" alt="forgot-password" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
